<template>
    <div class="DfEditPgHeader">
        <div class="container">
            <div class="py-3">
                <p class="weight-6 mb-0">{{ $t('Groups') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * PropertyGroups sub-view header.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'DfEditPgHeader'
};
</script>
