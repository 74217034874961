<template>
    <div class="DfEditPgPdHeader">
        <div class="container">
            <div class="d-flex justify-content-start align-items-center py-3">
                <p class="mb-1 mr-2 weight-6 cursor-pointer" @click="goToPgListing">
                    <i class="fas fa-chevron-left"></i>
                </p>
                <p class="weight-6 mb-0">
                    {{ propertyGroupsByName[pgName].label }}
                    <small class="ml-1">{{ $tc('df_properties_with_count', propertyGroupsByName[pgName].pdCount, { count: propertyGroupsByName[pgName].pdCount }) }}</small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * PropertyGroup PropertyDefinitions listings sub-view header.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'DfEditPgPdHeader',
    props: {
        // Ensured by SchemaDefinitionEditView.explainNestedRoute
        pgName: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters('schemaDefinitionEdit', {
            propertyGroupsByName: 'propertyGroupsByName'
        })
    },
    methods: {
        goToPgListing () {
            let query = Object.assign({}, this.$route.query);
            delete query.pgName;
            this.$router.replace({ query });
        }
    }
};
</script>
