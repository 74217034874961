<template>
    <div class="DfEditPgPdListing">
        <div class="my-4 text-center" v-if="propertyDefinitions.length === 0">
            <p class="mb-0">{{ $t('df_edit_pg_pd_listing_no_properties') }}</p>
            <p class="mb-0 small cursor-pointer text-danger"
               v-if="pgName !== 'general'"
               @click="deletePropertyGroupWithConfirmation">{{ $t('common_action_delete') }} {{ $t('Group') }}</p>
        </div>
        <draggable v-model="propertyDefinitions"
                   handle=".dnd-handle"
                   ghost-class="Draggable-Ghost--Default"
                   :force-fallback="true">
            <div class="gk-card as-listing-item"
                 v-for="propertyDefinition in propertyDefinitions"
                 :key="propertyDefinition.name">
                <div class="d-flex align-items-center justify-content-start">
                    <p class="mb-0 text-muted cursor-grab mr-2 dnd-handle">
                        <small class="text-muted">
                            <i class="fas fa-grip-lines"></i>
                        </small>
                    </p>
                    <p class="mb-0 size-6 line-height-2 mr-2">
                        {{ propertyDefinition.label }}
                        <span class="text-microcopy--70 weight-7 ml-1">{{ propertyDefinition.typeLabel }}</span>
                    </p>
                    <router-link tag="p"
                                 class="mb-0 small text-muted cursor-pointer ml-auto py-2 px-3"
                                 :to="getPropertyDefinitionEditRoute(propertyDefinition.name)">
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </div>
            </div>
        </draggable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

/**
 * PropertyGroup PropertyDefinitions listings sub-view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'DfEditPgPdListing',
    components: {
        draggable
    },
    props: {
        // Ensured by SchemaDefinitionEditView.explainNestedRoute
        pgName: {
            type: String,
            required: true
        }
    },
    computed: {
        ...mapGetters('schemaDefinitionEdit', {
            propertyDefinitionsPerGroupDnD: 'propertyDefinitionsPerGroupDnD',
            // df-dynamic sub-module
            schemaDefinitionName: 'schemaDefinitionName'
        }),
        propertyDefinitions: {
            get () {
                return this.propertyDefinitionsPerGroupDnD[this.pgName];
            },
            set (value) {
                this.$store.dispatch('schemaDefinitionEdit/changePropertyDefinitions', {
                    group: this.pgName,
                    data: value
                }).then(() => void 0).catch(() => void 0); // @future add toast in case of error?
            }
        }
    },
    methods: {
        getPropertyDefinitionEditRoute (pdName) {
            return {
                name: 'property-definition-edit',
                params: {
                    id: this.schemaDefinitionName + ':' + pdName
                }
            };
        },

        goToPgListing () {
            let query = Object.assign({}, this.$route.query);
            delete query.pgName;
            this.$router.replace({ query });
        },
        deletePropertyGroupWithConfirmation () {
            // Confirmation.
            let resp = confirm(this.$t('Delete Property Group?'));
            if (!resp) return;

            // This is required in order to ensure a smooth UX.
            this.$store.commit('schemaDefinitionEdit/setPropertyGroupDeleting', true);
            this.goToPgListing();

            // Perform the delete request.
            this.$store.dispatch('schemaDefinitionEdit/deletePropertyGroupAndSynchronizeIndex', this.pgName)
                .then(() => {
                    this.toastDefault('Property Group deleted');
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        }
    }
};
</script>
