<template>
    <div class="SchemaDefinitionEditView ViewWrapper LwHaB">
        <clv-head-meta :title="[
            stateIsChanging === true ? $t('common_state_loading') + '...' : null,
            pageTitle,
            $t('Not Found')
        ]"></clv-head-meta>

        <!-- VIEW LOADER -->
        <element-loading :active="stateIsChanging" :is-full-view="true" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="displayEmptyState">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'tools' }"><i class="fas fa-chevron-left"></i> {{ $t('Tools') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="displayMain">
            <!-- VIEW HEADER -->
            <schema-definition-edit-header></schema-definition-edit-header>
            <!-- SUB-VIEW HEADER -->
            <component :is="activeSubViewHeaderComponent" :pg-name="activePgName"></component>
            <div class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body container">
                <!-- SUB-VIEW BODY -->
                <component :is="activeSubViewBodyComponent" :pg-name="activePgName"></component>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterUtils from '@/common/utils/router.utils';
import SchemaDefinitionEditHeader from './SchemaDefinitionEditHeader';
import DfEditPgHeader from './DfEditPgHeader';
import DfEditPgListing from './DfEditPgListing';
import DfEditPgPdHeader from './DfEditPgPdHeader';
import DfEditPgPdListing from './DfEditPgPdListing';

/**
 * SchemaDefinition edit view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'SchemaDefinitionEditView',
    components: {
        SchemaDefinitionEditHeader
    },
    computed: {
        ...mapGetters('schemaDefinitionEdit', {
            pageTitle: 'pageTitle',
            propertyGroupsDnD: 'propertyGroupsDnD',

            stateIsChanging: 'stateIsChanging',
            displayEmptyState: 'displayEmptyState',
            displayMain: 'displayMain'
        })
    },
    data () {
        return {
            activePgName: null,
            activePdName: null,
            activeSubViewHeaderComponent: null,
            activeSubViewBodyComponent: null
        };
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                this.$store.commit('schemaDefinitionEdit/setSchemaDefinitionName', result.toId);

                // On route change, initialize.
                if (result.mustInitialize) {
                    this.$store.dispatch('schemaDefinitionEdit/resetModule').then(() => {
                        this.$store.commit('schemaDefinitionEdit/setSchemaDefinitionName', result.toId);
                        this.$store.dispatch('schemaDefinitionEdit/initializeModule').then(() => {
                            this.explainNestedRoute(to);
                        }).catch(() => void 0);
                    }).catch(() => void 0);
                } else {
                    this.explainNestedRoute(to);
                }
            },
            immediate: true
        }
    },
    methods: {
        explainNestedRoute (route) {
            let pgName = route.query.pgName; // PropertyGroup name.
            // let pdName = route.query.pdName; // PropertyDefinition name.

            // In this check relies the sub-views functionality!
            const propertyGroupExists = this.$store.getters['schemaDefinitionEdit/propertyGroupExists'](pgName);

            if (propertyGroupExists) {
                this.activePgName = pgName;
                this.activeSubViewHeaderComponent = DfEditPgPdHeader;
                this.activeSubViewBodyComponent = DfEditPgPdListing;
            } else {
                this.activePgName = null;
                this.activeSubViewHeaderComponent = DfEditPgHeader;
                this.activeSubViewBodyComponent = DfEditPgListing;
            }
        }
    }
};
</script>
