<template>
    <div class="SchemaDefinitionEditHeader bg-white border-bottom">
        <div class="container">
            <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center py-3">
                <div>
                    <p class="mb-0 size-5 weight-7 line-height-2">{{ pageTitle }}</p>
                </div>
                <div class="mt-2 mt-md-0">
                    <router-link tag="button"
                                 class="btn btn-primary"
                                 :to="{ name: 'property-definition-edit', params: { id: schemaDefinitionName } }">
                        {{ $t('common_action_create') }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * The view header.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'SchemaDefinitionEditHeader',
    computed: {
        ...mapGetters('schemaDefinitionEdit', {
            // df-dynamic sub-module
            schemaDefinitionName: 'schemaDefinitionName',
            // index
            pageTitle: 'pageTitle'
        })
    }
};
</script>
