<template>
    <div class="DfEditPgListing">
        <draggable v-model="propertyGroups"
                   handle=".dnd-handle"
                   ghost-class="Draggable-Ghost--Default"
                   :force-fallback="true">
            <div class="gk-card as-listing-item"
                 v-for="propertyGroup in propertyGroups"
                 :key="propertyGroup.name">
                <div class="d-flex align-items-center justify-content-start">
                    <p class="mb-0 text-muted cursor-grab mr-2 dnd-handle">
                        <small class="text-muted">
                            <i class="fas fa-grip-lines"></i>
                        </small>
                    </p>
                    <p class="mb-0 size-6 line-height-2 mr-2">
                        {{ propertyGroup.label }}
                        <span class="d-inline-block d-md-none text-microcopy--70 weight-7 ml-1">({{ propertyGroup.pdCount }})</span>
                        <span class="d-none d-md-inline-block text-microcopy--70 weight-7 ml-1">{{ $tc('df_properties_with_count', propertyGroup.pdCount, { count: propertyGroup.pdCount }) }}</span>
                    </p>
                    <router-link tag="p"
                                 class="mb-0 small text-muted cursor-pointer ml-auto py-2 px-3"
                                 :to="{ query: { pgName: propertyGroup.name } }">
                        <i class="fas fa-chevron-right"></i>
                    </router-link>
                </div>
            </div>
        </draggable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

/**
 * PropertyGroups listing sub-view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 25 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'DfEditPgListing',
    components: {
        draggable
    },
    computed: {
        ...mapGetters('schemaDefinitionEdit', {
            propertyGroupsDnD: 'propertyGroupsDnD'
        }),
        propertyGroups: {
            get () {
                return this.propertyGroupsDnD;
            },
            set (value) {
                this.$store.dispatch('schemaDefinitionEdit/changePropertyGroups', {
                    data: value
                }).then(() => void 0).catch(() => void 0); // @future add toast in case of error?
            }
        }
    }
};
</script>
